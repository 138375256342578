import { navigate } from "@reach/router"
import * as fb from '../firebase';
import { User } from '../models/user';
import { firebaseApp } from '../firebase';
import { TokenActions } from './token';
import UserStore from '../stores/user';
import PostStore from '../stores/post';
import TokenStore from '../stores/token';
import { ROUTES, API_PATH } from '../constants';


export class UserActions {

  /**
   * Checkout
   */
  static checkout(planId: string, tokenId: string, couponId?: string) {

    const checkout = firebaseApp.functions().httpsCallable(`${API_PATH}/billing/checkout`);
    return checkout({ planId, tokenId, couponId })
    .then(res => {

      let user = new User(res.data);
      UserStore.setUser(user);

      // TODO: Have notification in app of success?
      navigate( ROUTES.settings )

      return user;
    })
  }

  /**
   * Cancel
   */
  static cancel() {

    const cancel = firebaseApp.functions().httpsCallable(`${API_PATH}/billing/cancel`);
    return cancel()
    .then(res => {

      let user = new User(res.data);
      UserStore.setUser(user);

      return user;
    })
  }

  /**
   * Apply coupon
   */
  static applyCoupon(couponId: string) {

    const checkout = firebaseApp.functions().httpsCallable(`${API_PATH}/billing/coupon`);
    return checkout({ couponId })
    .then(res => {

      let user = new User(res.data);
      UserStore.setUser(user);

      return user;
    })
  }

  /**
   * Handle adding of user credit card via stripe
   */
  static selectPlan(planId: string, tokenId: string) {

    const saveToken = firebaseApp.functions().httpsCallable(`${API_PATH}/user/plan`);
    return saveToken({ planId, tokenId })
  }

  /**
   * Handle adding of user credit card via stripe
   */
  static removeCard(id: string) {

    const saveToken = firebaseApp.functions().httpsCallable(`${API_PATH}/user/card/${id}`);
    return saveToken({ id })
    .then(res => {

      // Delete card locally
      UserStore.delCard(id);
    })
  }

  public static setUser(res) {
    // let user = User.fromFirebase(res);
    UserStore.setUser(res);
  }

  /**
   * Update user settings
   */
  public static async updateUserSettings(user: User) {
    user.postDelayTime = Number(user.postDelayTime) || null;
    user.postAutoDeleteTime = Number(user.postAutoDeleteTime) || null;
    user.customPrompt = String(user.customPrompt) || null;

    let data = {
      postDelayTime: user.postDelayTime, postAutoDeleteTime: user.postAutoDeleteTime, customPrompt: user.customPrompt,
    }
    return fb.userCollection.doc(user.id)
      .update(data)
      .then((doc) => {
        return UserStore.setUser(user);
      });
  }

  /**
   * Load user
   * - Called on page load
   */
  static load() {
    const loadUser = firebaseApp.functions().httpsCallable(`${API_PATH}/user/get`);
    return loadUser()
    .then(res => {

      let user = UserActions.userLoaded(res.data);
      return user;
    })
  }

  /**
   * Sigin
   *
   * - Auth flow for when user signs up
   * @param authResult
   * @param redirectUrl
   */
  public static async signin(authResult, redirectUrl) {
    const user = authResult.user

    // Save oauth token for twitter
    let fn = firebaseApp.functions().httpsCallable(`${API_PATH}/user/signup`)

    return fn({userId: user.uid})
    .then(res => {

      // Load oauth tokens
      // NOTE: Don't stop login process on fail
      TokenActions.loadTokens()
      .catch(err => {
        console.log('Failed to load tokens: ', err);
      })

      UserActions.userLoaded(res.data, ROUTES.home);
    })
  }

  /**
   * Logic for onload of user
   *
   * - Either page reload, signup, login
   */
  static userLoaded(data: firebase.functions.HttpsCallableResult['data'], redirectUrl?: string) {

    // Set user
    let user = new User(data);
    UserStore.setUser(user);

    // Start monitoring posts
    PostStore.startPostWatch(user.id);

    // If there are no subscriptions for the user
    // send to checkout
    if (!UserStore.hasActiveSubscription()) {
      navigate(ROUTES.checkout)
    }
    else if (redirectUrl) {
      navigate(redirectUrl)
    }

    return user;
  }

  static async signout(e) {
    if (e) {
      e.preventDefault();
    }

    await firebaseApp.auth().signOut();

    // Clear out user data
    UserStore.reset();
    PostStore.reset();
    TokenStore.reset();

    return navigate(ROUTES.signup)
  }

  static loadPlans() {
    return firebaseApp.functions().httpsCallable(`${API_PATH}/billing/plans`)()
    .then(res => res.data);
  }
}