import  { isNil } from 'lodash';
import Store from '../core/store';
import { User } from "../models/user";

class UserStore extends Store {
  
  user: User;

  get() {
    return this.user;
  }

  setUser(user: User) {
    this.user = user;

    this.emitChange();
  }

  isAuthenticated() {
    return !isNil(this.user);
  }

  reset() {
    this.user = null;

    this.emitChange();
  }

  delCard(id: string) {
    if (this.user.customer.sources && this.user.customer.sources.data.length > 0) {
      this.user.customer.sources.data = this.user.customer.sources.data.filter(source => {
        return source.id !== id;
      }) 

      this.emitChange();
    }
  }

  /**
   * Check if user has active billing subscription
   */
  hasActiveSubscription() {
    if (!this.user.customer) {
      return false;
    }

    return this.user.customer.subscriptions.data.length > 0;
  }
}

export default new UserStore();