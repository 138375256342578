import  { isNil } from 'lodash';
import Store from '../core/store';
import { OauthToken } from "../models/oauth_token";

class TokenStore extends Store {
  
  tokens: {[id: string]: OauthToken} = {};
  activeToken: string;

  getAll() {
    return this.tokens;
  }

  getActive() {
    return this.tokens[this.activeToken] || null;
  }

  getActiveSettings() {
    if (this.tokens[this.activeToken] && this.tokens[this.activeToken].settings) {
      return this.tokens[this.activeToken].settings;
    }
    return null;
  }

  getTwitterUser() {
    if (this.tokens[this.activeToken] && this.tokens[this.activeToken].account) {
      return this.tokens[this.activeToken].account;
    }

    return null;
  }

  del(id: string) {
    delete this.tokens[id];
    
    if (this.activeToken === id) {
      this.activeToken = null;
    }

    this.emitChange();
  }

  set(id: string, token: OauthToken) {
    this.tokens[id] = token;

    this.emitChange();
  }

  load(data: Array<OauthToken>) {

    data.forEach(item => {
      this.tokens[item.id] = new OauthToken(item);
    })

    // Set a default active token
    if (!this.activeToken && data.length > 0) {
      this.activeToken = data[0].id;
    }

    this.emitChange();
  }

  reset() {
    this.tokens = {};

    this.emitChange();
  }
}

export default new TokenStore();