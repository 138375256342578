import React from "react";
import { differenceInSeconds, parseISO } from 'date-fns';
import { ProgressBar } from 'react-bootstrap';
window.differenceInSeconds = differenceInSeconds;
window.parseISO = parseISO;

export default class Countdown extends React.Component  {

  state = {
    scheduled: null,
    seconds: 0,
    totalSeconds: 0
  }

  countdown: null|number;

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.post && nextProps.post.scheduled && nextProps.post.scheduled !== prevState.scheduled) {

      let seconds = differenceInSeconds(parseISO(nextProps.post.scheduled), new Date());

      if (seconds < 0) {
        return null;
      }

      // Get post to edit
      return { 
        scheduled: nextProps.post.scheduled, 
        seconds: seconds,
        totalSeconds: seconds
      }
    }

    return null;
  }

  componentDidMount() {

    // If in edting mode
    if (this.state.scheduled) {
      this.startCountdown();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (this.state.scheduled && prevState.scheduled !== this.state.scheduled) {
      this.startCountdown();
    }
    else if (prevProps.post.scheduled && !this.props.post.scheduled) {
      this.stopCountdown();
      this.setState({seconds: 0, totalSeconds: 0})
    }
  }

  startCountdown() {
    if (this.countdown) {
      return;
    }

    this.countdown = setInterval(()=> {
      if (this.state.seconds === 0) {
        this.stopCountdown();
      }
      else {
        let seconds = this.state.seconds - 1;
        this.setState({seconds: seconds})
      }
    }, 1000)
  }

  stopCountdown() {
    if (this.countdown) {
      clearInterval(this.countdown);
      this.countdown = null;
    }
  }

  componentWillUnmount() {
    this.stopCountdown();
  }

  render() {
    let now = this.state.totalSeconds - this.state.seconds;

    if (!this.props.post || !this.props.post.scheduled || now <= 0) {
      return <span></span>
    }

    if (this.props.counter) {
      return <span className="counter">{this.state.seconds}</span>
    }

    return (
      <ProgressBar now={now} max={this.state.totalSeconds}/>
    )
  }
}