import React from "react";

import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Container,
  Button,
  Form,
} from "react-bootstrap";
import classnames from 'classnames';
import CheckoutForm from "../components/CheckoutForm";
import config from "../config";
import { UserActions } from "../actions/user";
import UserStore from "../stores/user";
import { ALERTS } from '../constants';

export default class Checkout extends React.Component {

  props: {
    showError?: (message: string, type?: string)=>void
  }

  state = {
    user: UserStore.get(),
    selectedPlan: "",
    plans: []
  };

  componentDidMount() {
    UserStore.addChangeListener(this.onUserChange);

    this.loadPlans();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserChange);
  }

  render() {
    let selectedPlan = this.state.plans.find(plan => plan.id === this.state.selectedPlan);

    return (
      <Container fluid className="main">
        <Row className="pt-4">
          <Col xs={0} md={1}></Col>
          <Col xs={12} md={10}>
          <h3 className="block-heading mb-4">Select a plan to start your 2-week free trial</h3>
          {this.renderPlans()}
          <p>Please fill out your credit card information below.</p>
          <p>Your card will not be charged until the end of your two-week free trial. </p>
            <div className="checkout">
              <div className="credit-card">
                <StripeProvider apiKey={config.stripe.key}>
                  <div>
                    <Elements>
                      <CheckoutForm
                        selectedPlan={selectedPlan}
                        user={this.state.user}
                        {...this.props}/>
                    </Elements>
                  </div>
                </StripeProvider>
              </div>
              </div>
          </Col>
          <Col xs={0} md={1}></Col>
        </Row>
      </Container>
    );
  }

  renderAmount(amount: number) {
    return `$${amount / 100}`;
  }

  renderPlans() {
    let plans = this.state.plans
    .sort((a, b)=> {
      return a.order - b.order;
    })
    .map(plan => {
      let classes = classnames('col-12', 'col-lg-4', 'card mb-4', 'shadow-sm', 'suggested-plan', {'active': this.state.selectedPlan === plan.id} );

      return (
        <div className={classes} 
          key={plan.id}
          onClick={this.handlePlan.bind(this, plan.id)}>
          <div className="card-body">
            <h5 className="card-title">{plan.name}</h5>
            <p dangerouslySetInnerHTML={{__html: plan.description }}></p>
            <p className="text-muted">{this.renderAmount(plan.amount)} {plan.terms}</p>
          </div>
        </div>
      );
    });

    return (
      <div className="card-deck mb-3 text-left">
        {plans}
      </div>
    );
  }

  handlePlan = id => {
    this.setState({ selectedPlan: id });
  };

  onUserChange = () => {
    this.setState({ user: UserStore.get() });
  };

  loadPlans() {
    UserActions.loadPlans()
      .then(result => {

        let defaultPlan = result.data.find(plan => plan.default === true);

        // Default to first plan?
        let nextState = {
          plans: result.data,
          selectedPlan: defaultPlan.id
        };

        this.setState(nextState);
      })
      .catch(err => {
        this.props.showError(ALERTS.plansLoadErr)
      });
  }
}
