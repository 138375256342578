import * as fb from '../firebase';
import { firebaseApp } from '../firebase';
import { OauthToken } from '../models/oauth_token';
import config from '../config';
import AuthPopup from '../components/AuthPopup';
import { API_PATH } from '../constants';
import TokenStore from '../stores/token';

export class TokenActions {

  public static loadTokens() {
    const getTokens = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/token/get`);
    return getTokens()
    .then((result) => {


      TokenStore.load(result.data);
    })
  }

  /**
   * Update user settings
   */
  public static async updateSettings(id: string, token: OauthToken) {

    const fn = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/token/${id}`);
    return fn(token)
    .then((result) => {

      TokenStore.set(id, new OauthToken(result.data));
    })
  }

  public static delToken(id: string) {
    const getTokens = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/token/${id}/del`);
    return getTokens()
    .then(res => {

      TokenStore.del(id);
    })
  }

  /**
   * Link Twitter
   */
  static linkTwitter() {
    let uid = firebaseApp.auth().currentUser.uid;

    return firebaseApp.auth().currentUser.getIdToken()
    .then(async (idToken)=> {

      const url = `${config.API_URL}/brizzly/twitter/oauth?userId=${uid}`;

      await  AuthPopup(url);

      // Now that twitter is linked, reload user to get twitter info
      await TokenActions.loadTokens();
    })
  }
}