import { Post } from "../models/post";
import Store from '../core/store';
import { postCollection } from '../firebase';
import { PostActions } from '../actions/post';

class PostStore extends Store {

  posts: {[id: string]: Post}

  constructor() {
    super();

    this.posts = {};

    this.observer = null;
  }

  startPostWatch(userId: string) {

    if (this.observer) {
      return;
    }

    let query = postCollection
    .where('userId', '==', userId)
    .orderBy("created", 'desc')
    .limit(5);

    // Listen for post changes
    this.observer = query.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        return;
      }

      let posts = querySnapshot.docs.map(doc => {
        return Post.fromFirebase(doc);
      });

      posts.forEach(post => {

        // If this post is stored as currently pending
        // Refresh it to load twitter data
        if (this.posts[post.id] && post.status === 'posted') {
          PostActions.loadLivePost(post.id);
        }
      })
    }, err => {
      console.log(`Firestore error: ${err}`);

      this.observer = null;
    });
  }

  getAll() {
    return this.posts;
  }

  getAllByCreated() {
    return Object.keys(this.posts).map(id => {
      return this.posts[id];
    })
    .sort( (a,b)=> {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    });
  }

  getById(id: string) {
    return this.posts[id] || null;
  }

  add(post: Post) {

    // Add new post to top
    this.posts[post.id] = post;

    this.emitChange();
  }

  del(id: string) {
    delete this.posts[id];

    this.emitChange();
  }

  load(data: Array<Post>) {
    let posts = {};

    data.forEach(item => {
      posts[item.id] = new Post(item);
    });
    this.posts = posts;

    this.emitChange();
  }

  reset() {
    this.posts = {};
  }
}

export default new PostStore();