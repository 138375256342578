import * as Stripe from "stripe";
import { FullUser } from 'twitter-d';

export const DEFAULT_POST_DELAY = 15; // default delay in seconds

export class User {

  id: string;
  created: string;
  updated: string;

  name: string;
  photoURL: string;
  email: string;

  // Billing
  stripeCustomerId: string;
  customer: Stripe.customers.ICustomer;

  /**
   * User settings
   */
  postDelayTime: number; // time before posting new tweet (undo time)

  // Default time to keep posts before delete
  postAutoDeleteTime: number; // seconds
  customPrompt: string;

  constructor(params?: User|any) {
    params = params || {};

    this.id = params.id || null;
    this.created = params.created;
    this.updated = params.updated;

    this.name = params.name || null;
    this.photoURL = params.photoURL || null;
    this.email = params.email || null;

    // Billing
    this.stripeCustomerId = params.stripeCustomerId || null;
    this.customer = params.customer || null;

    /**
     * User settings
     */
    this.postDelayTime = params.postDelayTime || DEFAULT_POST_DELAY;
    this.postAutoDeleteTime = params.postAutoDeleteTime || null;
    this.customPrompt = params.customPrompt || null;
  }

  toFirebase() {
    return {
      created: this.created,
      updated: this.updated,

      name: this.name,
      photoURL: this.photoURL,
      email: this.email,

      postDelayTime: this.postDelayTime,
      postAutoDeleteTime: this.postAutoDeleteTime,
      customPrompt: this.customPrompt
    }
  }

  static fromFirebase(doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) {
      throw new Error(`id: ${doc.id} does not exists`);
    }
    let data: any = doc.data() || {};
    let user = new User(data || {});
    user.id = doc.id;

    return user;
  }
}