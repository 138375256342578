import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import { Link, Match } from "@reach/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome,faUserAstronaut,faFeather,faCog,faBell,faNewspaper } from '@fortawesome/free-solid-svg-icons'
import UserStore from '../stores/user';
import { ROUTES } from '../constants';

export default class FooterComponent extends React.Component {

  componentDidMount() {
    UserStore.addChangeListener(this.onUserChange);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserChange);
  }
  
  render() {
    let user = UserStore.get();
    return user ? this.loggedIn() : this.loggedOut();
  }

  /**
   * Don't render footer on /checkout
   */
  loggedIn() {
    return (
      <Match path={ROUTES.checkout}>
        {props =>
          !props.match && this.appFooter()
        }
      </Match>
    )
  }

  appFooter() {
    return (
      <footer className="footer">
      <Navbar sticky="bottom" bg="dark" variant="dark h-120">
        <Nav className="m-auto">
          <Link to={ROUTES.home} className="pl-4 pr-4 nav-link" alt="Home" title="Home">
            <FontAwesomeIcon icon={faHome} size="2x"/>
          </Link>
    
          <Nav.Link href={ROUTES.settings} className="pl-4 pr-4" alt="Settings" title="Settings">
            <FontAwesomeIcon icon={faCog} size="2x" />
          </Nav.Link>

          <Link to={ ROUTES.profile } className="pl-4 pr-4 nav-link" alt="Profile" title="Profile">
            <FontAwesomeIcon icon={faUserAstronaut} size="2x"/>
          </Link>
        </Nav>
      </Navbar>
      </footer>
    )
  }

  loggedOut() {
    return (
      <footer className="footer">
      {/* <Navbar sticky="bottom" bg="dark" variant="dark">
        <Nav className="m-auto">
          <Link to="/" className="pl-4 pr-4 nav-link">
            <FontAwesomeIcon icon={faHome} size="2x"/>
          </Link>

          <Nav.Link href="/" className="pl-4 pr-4">
          <FontAwesomeIcon icon={faNewspaper} size="2x" />
          </Nav.Link>
    
          <Nav.Link href="/" className="pl-4 pr-4">
          <FontAwesomeIcon icon={faBell} size="2x"/>
          </Nav.Link>
    
          <Link to="/profile" className="pl-4 pr-4 nav-link">
            <FontAwesomeIcon icon={faUserAstronaut} size="2x"/>
          </Link>
        </Nav>
      </Navbar> */}
      </footer>
    )
  }

  onUserChange = ()=> {
    this.setState({user: UserStore.get() })
  }
}