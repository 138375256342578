import React from "react";
import { Link } from "@reach/router";
import TwitterAuth from '../components/TwitterAuth';
import UserSettings from '../components/UserSettings';
import { Container,Col,Row,Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from "../models/user";
import { ROUTES } from '../constants';
import TokenStore from '../stores/token';
import { ALERTS } from '../constants';
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default class SettingsPage extends React.Component  {

  state = {
    activeAccount: TokenStore.getActive(),
    showError: false,
  }

  componentDidMount() {
    TokenStore.addChangeListener(this.onTokenChange);
  }

  componentWillUnmount() {
    TokenStore.removeChangeListener(this.onTokenChange);
  }

  render() {
    return (
      <div>
          <Container fluid className="main">
          <Row className="pt-2 pb-4">
            <Col xs={0} md={3}></Col>
            <Col xs={12} md={6}>
              <h5 className="pt-2 pb-2">Settings</h5>
              <hr></hr>
              <h5 className="pt-2 pb-2 pl-2">Twitter Account</h5>
              <div className="container-fluid bg-light border-top border-bottom pt-2 pb-2">
                <div className="row">
                {this.renderTwitterProfile()} 
                  <div className="col align-center pt-4">
                    <TwitterAuth {...this.props}/>
                  </div>
                </div>
               </div>
              <UserSettings/>
              <h4 className="pt-4 pb-2 clearfix">Billing</h4>
                <Link to={ROUTES.billing} className="link mb-4">
                  <FontAwesomeIcon icon={faCreditCard} size="1x"/> Manage Billing
                </Link>

              <h4 className="pt-4 pb-2 clearfix">Support</h4>
              <a href="/support" className="link mb-4">
                <FontAwesomeIcon icon={faQuestionCircle} size="1x" className="mr-2"/>FAQ and contact info</a>
              </Col>
              <Col xs={0} md={3}></Col>  
            </Row>
          </Container>
          <br></br>
      </div>
    )
  }

  renderTwitterProfile() {
    // Get active token
    let activeAccount = this.state.activeAccount;

    if (activeAccount && activeAccount.account) {
      return (
        <div className="col pb-4 mt-3 mb-3 ml-2">
          <Image src={activeAccount.account.profile_image_url_https} roundedCircle /> <strong>@{activeAccount.account.screen_name}</strong>
        </div>
      )
    }
  }

  onTokenChange = ()=> {
    this.setState({
      activeAccount: TokenStore.getActive()
    });
  }
}