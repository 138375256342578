export const ROUTES = {
  home: '/',
  signup: '/get',
  checkout: '/checkout',
  billing: '/billing',
  profile: '/profile',
  settings: '/settings'
}

export const ALERTS = {
  defaultErrorHeader: 'Ooops!',
  defaultErrorBody: 'We ran into an issue.',

  // TODO: What should the generic error message be?
  defaultOops: 'Oops! We ran into a problem doing that.',

  // Posts
  postLoadErr: 'We were not able to load your post.',
  postUpdateErr: 'We were not able to update your post.',
  postDeleteErr: 'We were not able to delete your post.',

  // Twitter / timeline stuff
  connectTwitter: 'Please Connect your Twitter account on the Settings page first',
  timelineLoadErr: 'We ran into an issue loading your timeline.',
  profileLoadErr: 'We ran into an issue loading your profile.',

  tweetUnlikeErr: 'We ran into an issue unliking this tweet.',
  tweetLikeErr: 'We ran into an issue liking this tweet.',

  // oauth
  oauthConnect: 'We were not able to link your account. Try again?',
  oauthDisconnect: 'We were not able to disconnect your account.',
  tokenLoadErr: `We were not able to load your Twitter status`,

  // user
  saveSettings: 'We were not able to save your settings.',

  // signup
  signinErr: 'Ooops! We ran into an issue signing you in.',

  // Checkout
  plansLoadErr: 'We ran into an issue loading available plans.',
  checkoutErr: 'We were not able to complete checkout.',
  couponErr: 'The coupon you entered is not valid.',
  cancelSuccess: 'Your account has been cancelled.',
}

export const ALERT_TYPES = {
  success: 'success'
}

export const API_PATH = 'brizzly/api'