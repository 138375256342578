import React from 'react'
import Photos from './Photos'
import Video from './Video'
import Previewer from "./Previewer";

class Media extends React.Component {
  render () {
    let tweet = this.props.data;
    let media;

    if (!tweet) {
      return <div />
    }

    // extended_entities override, these are multi images, videos, gifs
    if (tweet.extended_entities && tweet.extended_entities.media) {
      media = tweet.extended_entities.media;
    }
    else if (tweet.entities && tweet.entities.media) {
      media = tweet.entities.media;
    }

    if (media) {
      return this.renderMedia(media);
    }


    // Render previewer if no media
    if (!tweet.is_quote_status && tweet.entities && tweet.entities.urls && tweet.entities.urls[0] && tweet.entities.urls[0].expanded_url) {
      return <Previewer url={tweet.entities.urls[0].expanded_url}/>
    }
    
    return <div />
  }

  renderMedia(media) {
    switch (media[0].type) {
      case 'photo':
        return <Photos media={media}/>
      case 'video':
        return <Video media={media} />
      case 'animated_gif':
        return <Video gif={true} media={media} />
      default:
        return <div />
    }
  }
}

export default Media;