import React from "react";
import { Router, Link, Redirect } from "@reach/router";
import UserStore from './stores/user';
import { ROUTES } from './constants';

import HomePublic from './views/HomePublic';

import Home from './views/Home';
import Signup from './views/Signup';
import Settings from './views/Settings';
import Profile from './views/Profile';
import Billing from './views/Billing';
import Checkout from './views/Checkout';

export class AppRoutes extends React.Component {

  props: {
    showError?: (message: string, type?: string)=>void
  }
  
  render(){
    return (
      <Router>
        <RedirectOnAuth as={Signup} path={ ROUTES.signup } />
  
        <PrivateRoute as={Checkout} path={ ROUTES.checkout } {...this.props}/>
  
        <PrivateRoute as={Home} public={HomePublic} path={ROUTES.home} {...this.props} />
        <PrivateRoute as={Profile} path={ ROUTES.profile } {...this.props} />
        <PrivateRoute as={Settings} path={ ROUTES.settings } {...this.props} />
        <PrivateRoute as={Billing} path={ ROUTES.billing} {...this.props} />
        <NoMatch path="*"/>
      </Router>
    )
  }
}

class NoMatch extends React.Component {
  props: {
    path: string;
  }

  render() {
    return <h2>404</h2>;
  }
}



/**
 * Redirect on auth
 * - If logged in and try to go to signup page, redirect elsewhere
 */
const RedirectOnAuth = ({ as: Component, ...props }) => {
  if (UserStore.isAuthenticated()) {

    // If the user has an active billing subscription
    // Then continue to home
    if (UserStore.hasActiveSubscription()) {
      return <Redirect to={ROUTES.home} noThrow />
    }

    // Otherwise go to the checkout page
    else {
      return <Redirect to={ROUTES.checkout} noThrow />
    }
  }
  else {
    return  <Component {...props}/>;
  }
}

const PrivateRoute = ({ as: Component, ...props }) => {

  if (UserStore.isAuthenticated()) {

    // If the user has an active billing subscription
    // Or this is the checkout page
    if (UserStore.hasActiveSubscription() || props.path === ROUTES.checkout) {
      return  <Component {...props}/>;
    }

    // Otherwise go to the checkout page
    else {
      return <Redirect to={ROUTES.checkout} noThrow />
    }
  }
  else {

    // If a public version is defined
    if (props.public) {
      return  <props.public {...props}/>;
    }

    return <Redirect to={ ROUTES.signup } noThrow />
  }
}