import React from "react";
export default class HomePublic extends React.Component {

  props: {
    path?: string;
  };

  /**
   * @inheritDoc
   */
  render() {
    return (
  <div className="ibmplexsans bg-white">
    <div className="position-relative overflow-hidden p-2 p-md-3 m-md-3 text-center undone">
      <div className="col-md-7 p-lg-5 mx-auto">
      <h1 className="display-3 patua mt-2 mb-4">Mistakes were made.</h1>
      <p className="lead font-weight-normal mb-4">Brizzly<sup>+</sup> brings <strong>Undo</strong>, <strong>Redo</strong>, <strong>Auto-deletion</strong> and more to Twitter<sup>&#8482;</sup>.</p>
      
      <div class="embed-responsive embed-responsive-4by3">
      <iframe src="https://player.vimeo.com/video/396375024" width="640" height="415" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>

      <a className="btn btn-lg bg-warning mt-4" href="/checkout">Start Free Trial</a>
      </div>
    </div>
    
    <div className="d-md-flex flex-md-equal my-md-1 pl-md-3">
      <div className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
        <div className="my-1 py-3">
          <h2 className="display-5 patua">Undo for Tweets</h2>
          <p className="lead">Ever wanted a few seconds or even minutes to revise or delete a tweet you just posted? Brizzly adds an "undo" button for Twitter.</p>
        </div>
      </div>
      
      <div className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
        <div className="my-1 p-3">
          <h2 className="display-5 patua">Auto-delete</h2>
          <p className="lead">Give your tweets an expiration date. Automatically delete after 24 hours, 7 days, or a month.</p>
        </div>
      </div>
    </div>
    
    <div className="d-md-flex flex-md-equal my-md-3 pl-md-3">
      <div className="brizzly-brown mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
        <div className="my-1 p-3 text-light">
          <h2 className="display-5 patua">Fave to Save</h2>
          <p className="lead">Even if you've turned on auto-delete, you can set a minimum number of likes for your tweets and they'll be saved.</p>
        </div>
      </div>
      
      <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden bg-dark">
        <div className="my-1 py-3">
          <h2 className="display-5 patua">Custom Prompts</h2>
          <p className="lead">Now "What's happening?" doesn't have to be the only thing you see before writing your tweets. Customize the prompt to say "What's gotten into you today?" or "What kind of sandwich are you eating?"</p>
        </div>
      </div>
    </div>

    <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center brizzly-back">
      <div className="col-md p-lg-5 mx-auto my-1">
      <a className="btn btn-lg bg-warning mb-4" href="/checkout">Start Now!</a>
        <h3 className="font-weight-normal patua">Get two weeks free. <br></br>Plans start at $2.49 a month<sup>*</sup></h3>
        <p className="lead font-weight-normal mw-20">* when paid annually</p>
      </div>
    </div>
    
    <footer className="container pt-3">
      <div className="row">
        <div className="col-12 col-md">
          <img src="favicon.ico" width="48" className="align-center" alt="The Brizzly bear head logo" title="This is our bear. There are many like it, but this one is ours. His name is Phineas T. Brizzly and he will eat your tweets if you ask nicely (and pay the subscription fee)."/>
          <small className="d-block mb-3 mt-3 text-muted align-center">&copy; 2020 - <a href="https://alittledrive.com">A Little Drive LLC</a></small>
        </div>
        <div className="col-6 col-md">
          <ul className="list-unstyled text-small">
            <li><a className="text-muted" href="https://blog.brizzly.com">The Brizzly Blog</a></li>
            <li><a className="text-muted" href="https://zero.brizzly.com" alt="The second interation of Brizzly, an always free way to vent your frustrations but send your thoughts to no one.">Try Brizzly Zero</a></li>
            <li><a className="text-muted" href="https://twitter.com/brizzly">@brizzly on Twitter</a></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <ul className="list-unstyled text-small">
            <li><a className="text-muted" href="/about">About</a></li>
            <li><a className="text-muted" href="/support">Support</a></li>
            <li><a className="text-muted" href="/privacy">Privacy</a></li>
            <li><a className="text-muted" href="/tos">Terms</a></li>
          </ul>
        </div>
      </div>
    </footer>
    </div>
);
  }
}