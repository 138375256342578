
import * as fb from '../firebase';
import PostStore from '../stores/post';
import TweetStore from '../stores/tweet';
import { API_PATH } from '../constants';

export class PostActions {

  /**
   * Refresh post
   */
  static loadLivePost(id: string) {
    const createPost = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/post/${id}/tweet`);
    return createPost().then((result) => {

      // Switch from post to active tweet
      PostStore.del(id);
      TweetStore.add(result.data);
    })
    .catch(err => {
      console.log('Error loading post');

    })
  }

  /**
   * Create post
   */
  public static createPost(data: {}) {
    const createPost = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/post`);
    return createPost(data).then((result) => {

      // Load data into store
      PostStore.add(result.data);

      return result.data;
    })
  }

  /**
   * Start edit of post
   * - Call server to stop tweet countdown if active
   * @param id
   */
  public static startEdit(id: string, loadPost?: boolean) {
    const edit = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/post/${id}/edit`);
    return edit({ id })
    .then((result) => {

      PostStore.add(result.data);

      return result.data;
    })
  }

  /**
   * End edit of post
   * - Call server to stop tweet countdown if active
   * @param id
   */
  public static endEdit(id: string, data) {
    const edit = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/post/${id}`);
    return edit(data)
    .then((result) => {
 
      // Load data into store
      PostStore.add(result.data);
      TweetStore.delByPostId(result.data.id);

      return result.data;
    })
  }

  public static deletePost(id: string) {
    const delPost = fb.firebaseApp.functions().httpsCallable(`${API_PATH}/post/${id}/del`);
    return delPost().then((result) => {

      // Load data into store
      PostStore.del(id);
      TweetStore.delByPostId(id);
    })
  }
}