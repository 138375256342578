import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import { Link, Match } from "@reach/router";
import { UserActions } from '../actions/user';
import UserStore from '../stores/user';
import { ROUTES } from '../constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class NavComponent extends React.Component {

  componentDidMount() {
    UserStore.addChangeListener(this.onUserChange);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserChange);
  }

  render() {
    let user = UserStore.get();

    return (
      <Navbar 
      variant="light" 
      className="top-navbar sticky-top"
      >
        <Link to={ROUTES.home} className="navbar-brand">
          <img
            alt="brizzly!"
            src="/brizzly-plus.svg"
            width="150px"
            className="align-top"
          />{' '}
        </Link>
          { user ? this.loggedIn() : this.loggedOut()}
      </Navbar>
    )
  }

  /**
   * Don't render settings link on /checkout
   */
  loggedIn() {
    return (
      <Nav className="ml-auto">
        <a 
          href={ROUTES.signup} 
          className="nav-link" 
          onClick={ UserActions.signout }>Sign Out</a>

        <a 
          href="/support"
          title="Need help? Visit our Support page."
          className="nav-link">
          <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
        </a>
       </Nav>
    )
  }

  loggedOut() {
    return (
      <Nav className="ml-auto">
        <Link to={ROUTES.signup} className="nav-link">Sign In</Link>
      </Nav>
    )
  }

  onUserChange = ()=> {
    this.setState({user: UserStore.get() })
  }
}