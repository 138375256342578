import React from "react";
import { Button, Modal } from 'react-bootstrap';
import { PostActions } from '../actions/post';
import TweetStore from '../stores/tweet';
import TokenStore from '../stores/token';
import { Tweet } from "../models/tweet";
import CreatePost from "../components/CreatePost";
import TweetComponent from "../components/Tweet";

const TYPES = {
  reply: 'reply',
  retweet: 'retweet'
}

export default class PostModal extends React.Component  {

  state = {
    message: '',
    type: '',
    tweet: null,

    isValid: false,
    submitting: false,
    cancelling: false,
    showError: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.open) {
      return {tweet: null, type: ''};
    }
    else if (nextProps.retweetId && !prevState.tweet) {
      let quotedTweet = TweetStore.getById(nextProps.retweetId);
      let tweet = new Tweet({
        id_str: nextProps.retweetId,
        created_at: Date.now(),
        full_text: '',
        is_quote_status: true,
        quoted_status: quotedTweet
      }, 'tweet');
        
      return {type: TYPES.retweet, tweet };
    }
    else if (nextProps.replyId && !prevState.tweet) {
      let tweet = TweetStore.getById(nextProps.replyId);
      return {type: TYPES.reply, tweet, 
      message: `@${tweet.user.screen_name} `};
    }

    return null;
  }

  render() {
    return (
      <Modal show={this.props.open} onHide={this.props.close} className="">
        <Modal.Header closeButton>
        </Modal.Header>
        
        {this.renderBody()}
      </Modal>
    );
  }

  renderBody() {
    let tweet = this.state.tweet;

    if (this.state.type === TYPES.retweet) {
      return (
        <div>
          <Modal.Body>
            <CreatePost
              isRetweet={true}
              onChange={this.onChange}/>
          </Modal.Body>

          {tweet && <TweetComponent
            key={tweet.id_str}
            data={tweet}
            isRetweetPreview={true}
            {...this.props}
          />}

          <Modal.Footer>
            <Button 
              variant="primary" 
              onClick={this.retweet}
              disabled={this.state.submitting || !this.state.isValid}>
                {this.state.submitting ? 'Sending…' : 'Retweet'}
            </Button>
          </Modal.Footer>
        </div>
      )
    }

    else if (this.state.type === TYPES.reply) {
      return (
        <div>
          {tweet && <TweetComponent
            key={tweet.id_str}
            data={tweet}
            isReplyPreview={true}
            {...this.props}
          />}

          <Modal.Body>
            <CreatePost
              message={this.state.message}
              isReply={true}
              onChange={this.onChange}/>
          </Modal.Body>

          <Modal.Footer>
            <Button 
              variant="primary" 
              onClick={this.reply}
              disabled={this.state.submitting || !this.state.isValid}>
                {this.state.submitting ? 'Sending…' : 'Reply'}
            </Button>
          </Modal.Footer>
        </div>
      )
    }
  }

  retweet = ()=> {
    let token = TokenStore.getActive();
    let quotedStatusUrl = TweetStore.getStatusUrlById(this.props.retweetId);

    this.setState({ submitting: true, showError: false })

    let post = {
      message: `${this.state.message.trim()} ${quotedStatusUrl}`,
      tokenId: token.id,
    }

    PostActions.createPost(post)
      .then((result) => {
        this.setState({
          message: '',
          submitting: false
        })

        this.props.close();
      })
      .catch((err) => {
        this.setState({
          submitting: false,
          showError: true
        })
      });
    
  }

  reply = ()=> {
    let token = TokenStore.getActive();
    this.setState({ submitting: true, showError: false })

    // If only the screen_name without a message
    let screen_name = this.state.tweet.user.screen_name;
    if (this.state.message.trim() === `@${screen_name}`) {
      return
    }

    let post = {
      message: this.state.message.trim(),
      tweetReplyId: this.props.replyId,
      tokenId: token.id,
    }

    PostActions.createPost(post)
      .then((result) => {
        this.setState({
          message: '',
          submitting: false
        })

        this.props.close();
      })
      .catch((err) => {
        this.setState({
          submitting: false,
          showError: true
        })
      });
    
  }

  onChange = (val: string, isValid)=> {
    this.setState({message: val, isValid});
  }
}