
import * as React from 'react';
import { Button, Alert } from 'react-bootstrap';
import { TokenActions } from '../actions/token';
import TokenStore from '../stores/token';
import { ALERTS } from '../constants';
import UserSettings from './UserSettings';

class TwitterAuth extends React.Component {

  state = {
    loading: true,
    tokens: TokenStore.getAll(),
    activeToken: TokenStore.getActive(),

    showError: false,
    errorText: '',
    errorType: 'danger'
  }

  componentDidMount() {

    // Load existing tokens
    this._loadTokens();

    TokenStore.addChangeListener(this.onTokenChange);
  }

  componentWillUnmount() {
    TokenStore.removeChangeListener(this.onTokenChange);
  }

  render() {

    let tokens = Object.keys(this.state.tokens).map(id => {
      return (
        <Button 
          key={id} 
          variant="dark"
          disabled={this.state.loading}
          onClick={ this.disconnect.bind(this, id) }>
          Remove Account
        </Button>
      )
    })

    let connectBtn;
    if (tokens.length === 0) {
      connectBtn = (
        <Button 
          variant="primary" 
          disabled={this.state.loading}
          onClick={ this.onClick }>
          Connect Account
        </Button>
      )
    }

    return (
      <div className="mb-4">
        {this.renderAlert()}

        {connectBtn}

        <div>
          {tokens}
        </div>
      </div>
    )
  }

  renderAlert() {
    if (this.state.showError) {
      return (
        <Alert variant={this.state.errorType} onClose={() => this.setState({showError: false})} dismissible>
          <p>
            {this.state.errorText}
          </p>
        </Alert>
      )
    }
  }

  /**
   * Disconnect a token
   */
  disconnect(id: string) {
    this.setState({
      loading: true
    })

    // TODO: Move this to a store
    TokenActions.delToken(id)
    .then(res => {

      this.setState({
        showError: false,
        loading: false
      });
    })
    .catch(err => {
      this.setState({
        loading: false,
        showError: true,
        errorText: ALERTS.oauthDisconnect
      });
    })
  }

  /**
   * Link Twitter
   */
  onClick = async () => {
    this.setState({
      loading: true
    })

    TokenActions.linkTwitter()
    .then(async (idToken)=> {

      this.setState({
        loading: false,
        showError: false
      })

      // Reload tokens
      return this._loadTokens(true);
    })
    .catch(err => {
      this.setState({
        loading: false,
        showError: true,
        errorText: ALERTS.oauthConnect
      });
    })
  }

  _loadTokens(throwErr?: boolean) {
    return TokenActions.loadTokens()
    .then(tokens => {

      this.setState({
        loading: false
      });
    })
    .catch(err => {
      console.log('Error: ', err);
      

      if (throwErr) {
        throw err;
      }

      this.setState({
        loading: false,
        showError: true,
        errorText: ALERTS.tokenLoadErr,
      });
    })
  }

  onTokenChange = ()=> {
    this.setState({tokens: TokenStore.getAll() });
  }
}

export default TwitterAuth;
