import React from "react";
import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Alert, Spinner, Container,Col,Row } from 'react-bootstrap';
import { firebaseApp } from '../firebase';
import { UserActions } from '../actions/user';
import { ALERTS } from '../constants';

/**
 * The Splash Page containing the login UI.
 */
export default class Signup extends React.Component {

  props: {
    path?: string;
  };

  state = {
    renderLogin: true, // whether to show google login
    loading: true,

    showError: false,
    errorText: '',
    errorType: 'danger'
  }

  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {

      // On successful login, show loading state again, then call our api for user creation
      // TODO: Can we streamline this on the api side and avoid logic here?
      signInSuccessWithAuthResult: (authResult, redirectUrl)=> {

        // Handle signin
        this.signinSuccess(authResult, redirectUrl);

        // Tell Google we are handling next steps
        return false;
      },
      signInFailure: async (err)=> {
        console.log('Fail:', err);
        
        this.setState({
          showError: true,
          errorText: ALERTS.signinErr,
          errorType: 'danger'
        })
      },

      uiShown: ()=> {
        this.setState({loading: false})
      }
    },
    tosUrl: 'http://brizzly.com/tos',
    privacyPolicyUrl: 'http://brizzly.com/privacy',
    credentialHelper: 'none'
  };

  /**
   * @inheritDoc
   */
  render() {
    return (
      <Container fluid className="main signin">
       <Row className="pt-4">
        <Col xs={1} md={3}></Col>
        <Col xs={10} md={6}>
        
        <div className="signin-header">
          <h2 className="patua">Welcome to Brizzly!</h2>
          {this.renderLoader()}
        </div>

        {this.renderAlert()}
        </Col>
        <Col xs={1} md={3}></Col>
      </Row>

      <Row className="pt-4">
        <Col xs={0} md={3}></Col>
        <Col xs={12} md={6}>
            {this.renderLogin()}
        </Col>
        <Col xs={0} md={3}></Col>
      </Row>
    </Container>
    );
  }

  renderLogin() {
    if (this.state.renderLogin) {
      return (
        <div>
            <StyledFirebaseAuth uiConfig={this.uiConfig}
                                firebaseAuth={firebaseApp.auth()}/>
          </div>
      )
    }
  }

  renderLoader() {
    if (this.state.loading) {
      return(
        <div className="loader">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
  }

  renderAlert() {
    if (this.state.showError) {
      return (
        <Alert variant={this.state.errorType} onClose={() => this.setState({showError: false})} dismissible>
          <p>
            {this.state.errorText}
          </p>
        </Alert>
      )
    }
  }

  /**
   * Signin Success Callback
   */
  signinSuccess = (authResult, redirectUrl)=> {

    // Show loading again
    this.setState({loading: true, showError: false});

    // Call our api to sign in 
    UserActions.signin(authResult, redirectUrl)
    .catch(err => {

      this.setState({
        loading: true,
        showError: true,
        errorText: ALERTS.signinErr,
        errorType: 'danger',

        renderLogin: false
      }, ()=> {

        // NOTE: This is a hack to force Google to re-render UI when our system fails. Otherwise it disappears completely if Google auth was successful and our system failed.
        this.setState({
          renderLogin: true,
          loading: false
        })
      });
    })
  }
}