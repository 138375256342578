/* global window */

function getWindowArea() {
  let windowArea = {
    width: Math.floor(window.outerWidth * 0.8),
    height: Math.floor(window.outerHeight * 0.5),
    left: null,
    top: null
  };

  if (windowArea.width < 1000) { windowArea.width = 1000; }
  if (windowArea.height < 630) { windowArea.height = 630; }

  windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2));
  windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8));

  return windowArea;
}

const popup = (url: string) => {
  let windowArea = getWindowArea();
  const sep = (url.indexOf('?') !== -1) ? '&' : '?';
  const URL = `${url}${sep}`;
  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${windowArea.width},height=${windowArea.height},
    left=${windowArea.left},top=${windowArea.top}`;

  // Open the window
  const authWindow = window.open(URL, 'auth_popup', windowOpts);
  
  // Listen to message from child window
  const authPromise = new Promise((resolve, reject) => {
    var didAction = false;

    // Check if window was closed without approving oauth
    var timer = setInterval(function() {

        if(authWindow.closed) {
            clearInterval(timer);

            // Did the user do something, or just close the window?
            if (!didAction) {
              let err = new Error(`Oauth fail`);
              // err.code = 'auth_fail'
              reject(err);
            }
        }
    }, 1000);

    window.addEventListener('message', (e) => {

      // if (e.origin !== window.location.href) {
      //   authWindow.close();
      //   reject('Not allowed');
      // }

      // the user did something
      didAction = true;

      if (e.data && e.data.status === 'ok') {
        resolve();
        authWindow.close();
      } else {
        authWindow.close();
        let err = new Error('Unauthorised');
        reject(err);
      }
    }, false);
  });

  return authPromise;
};

export default popup;