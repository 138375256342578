import React from "react";
import { Container,Col,Row, Alert } from 'react-bootstrap';
import { Link } from "@reach/router";
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import { AppRoutes } from './router';
import Nav from './components/Nav';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const DEFAULT_ERR_TYPE = 'danger';

export default class App extends React.Component {

  state = {
    showError: false,
    errorText: '',
    errorType: DEFAULT_ERR_TYPE
  }

  render() {
    return (
      <div>
        {/* App router */}
        <Nav {...this.props}/>

        <AppRoutes showError={this.showError}/>

        <div className="global-alert">
            <div className="alert-inner">
              {this.renderAlert()}
            </div>
          </div>
        <Footer {...this.props}/>
      </div>
    );
  }

  showError = (message: string, type?: string, link?: string)=> {
    this.setState({
      showError: true,
      errorText: message,
      errorType: type || DEFAULT_ERR_TYPE,
      link: link || null
    })
  }

  renderAlert() {
    if (this.state.showError) {
      return (
        <Alert variant={this.state.errorType} onClose={() => this.setState({showError: false})} dismissible>
          <p>
            {this.state.errorText}
          </p>

          {this.state.link && (
            <div>
                <hr />
                <div className="d-flex justify-content-end">
                  <Link 
                   to={this.state.link} className="btn outline-success">
                   Go
                  </Link>
                </div>
            </div>
          )}

        </Alert>
      )
    }
  }
}
