import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

import config from './config';

console.log('Config:', config);
console.log('Emulator status: ', {
  env: process.env.REACT_APP_FIREBASE_ENV,
  emulator: process.env.REACT_APP_USE_EMULATOR,
  firestoreEmulator: process.env.REACT_APP_FIRESTORE_EMULATOR_HOST
});


export const firebaseApp = firebase.initializeApp(config.firebase);

// Initialize Cloud Functions through Firebase
var functions = firebase.functions();

// Firestore
const firestore = firebase.firestore();

// ADD THESE LINES
if (process.env.REACT_APP_USE_EMULATOR || window.location.hostname === "localhost") {
  console.log("localhost detected!");

  // Optionally point at local firestore
  if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
    firestore.settings({
      host: process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
      ssl: false
    });
  }

  // Set functions 
  functions.useFunctionsEmulator(config.API_BASE);
}

// Collections
export const userCollection = firestore.collection('user');
export const postCollection = firestore.collection('post');