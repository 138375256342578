import React from 'react'
import TextTruncate from 'react-text-truncate';
import Img from 'react-image';
import { Spinner } from 'react-bootstrap';
import { TimelineActions } from '../../actions/timeline';

class Previewer extends React.Component {

  state = {
    data: null
  }

  componentDidMount() {

    // Call url to get metadata
    this.loadUrl(this.props.url)
  }

  render () {
    if (!this.state.data) {
      return (
        <div>Loading</div>
      )
    }

    return (
      <div className="link-preview">
        <div className="img">
          <Img
            src={[ 
              this.state.data.image,
              `/photo-failed-to-load.png`
             ]}
            loader={
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            }
          />
        </div>
        <div>
          <a href={this.state.data.url} target="_blank">
            <div className="title">{this.state.data.title}</div>
            <div className="description">
              <TextTruncate
                line={2}
                element="span"
                truncateText="…"
                text={this.state.data.description} />
            </div>
            <div className="host">{this.state.data.host}</div>
          </a>
        </div>
      </div>
    )
  }

  loadUrl(url: string) {
    TimelineActions.loadPreview(url)
    .then( (response)=> {
      this.setState({data: response.data})
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }
}

export default Previewer;