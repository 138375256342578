import React from "react";
import { Form, Button, Spinner, Alert, Modal, Collapse } from 'react-bootstrap';
import TokenStore from '../stores/token';
import { UserActions } from "../actions/user";
import { ALERTS } from "../constants";
import { OauthToken, UI_MODES } from "../models/oauth_token";
import { TokenActions } from "../actions/token";
import InputGroup from 'react-bootstrap/InputGroup';

export default class UserSettings extends React.Component  {

  state = {
    saving: false,
    token: TokenStore.getActive(),

    showError: false,
    errorText: '',
    errorType: 'danger',
    showConfirmModal: false,
  }

  componentDidMount() {
    TokenStore.addChangeListener(this.onTokenChange);
  }

  componentWillUnmount() {
    TokenStore.removeChangeListener(this.onTokenChange);
  }
  
  render() {

    let settings;
    if (!this.state.token) {
      return <div/>
    }
    else {
      settings = this.state.token.settings;
    }

    return (
      <Form onSubmit={ this.onSubmit } className="pb-4 pt-4">
        <div className="pl-2 pr-2">
        <Form.Group controlId="postDelayTime">
          <Form.Label className="h3 pt-2 mt-2">Undo Send</Form.Label>
          <p className="text-muted">
            Adjust the amount of time you'll have to Undo your tweet before posting to Twitter. (After the Undo time you'll also have a chance to Redo your tweet as well.)
            </p>
          <Form.Control type="number" as="select" value={ String(settings.postDelayTime) }
          onChange={ this.onChange.bind(this, 'postDelayTime') }>
                <option label="10 seconds" value="10">10 seconds</option>
                <option label="15 seconds" value="15">15 seconds</option>
                <option label="30 seconds" value="30">30 seconds</option>
                <option label="1 minute" value="60">1 minute</option>
                <option label="3 minutes" value="180">3 minutes</option>
                <option label="5 minutes" value="300">5 minutes</option>
                <option label="10 minutes" value="600">10 minutes</option>
                <option label="*** No delay ***" value="1">No delay</option>
                </Form.Control>
        </Form.Group>
        </div>

        <div className="bg-light border-top border-bottom pl-2 pr-2">
          <Form.Group controlId="postAutoDeleteTime">
          <Form.Label className="h3 pt-2">Auto-delete my posts</Form.Label>
          <p className="text-muted pt-2">
          When a time frame is selected below, this feature will delete any new tweets from this point on for all tweets made <strong>on</strong> or <strong>off</strong> Brizzly. This will delete replies but will not delete tweets prior to using Auto-delete. Select a time-frame to see <strong>Fave to Save</strong> options.
          </p>
          <Form.Control type="number" as="select" 
          value={ String(settings.postAutoDeleteTime) }
          onChange={ this.onChange.bind(this, 'postAutoDeleteTime') }>
              <option type="radio" label="No! Do not delete my tweets!" value="" className="ml-2">No! Do not delete my tweets!</option>
              <option type="radio" label="Delete my tweets 24 hours after posting" value="86400" className="ml-2">Delete tweets 24 hours after posting</option>
              <option type="radio" label="Delete my tweets 1 week after posting" value="604800" className="ml-2">Delete tweets 1 week after posting</option>
              <option type="radio" label="Delete my tweets 1 month after posting" value="2592000" className="ml-2">Delete tweets 1 month after posting</option>
         </Form.Control>
        </Form.Group>        
        </div>
         {this.renderDeleteSettings()}

         {this.renderConfirmModal()}

         <div className="pl-2 pr-2">
        <Form.Group controlId="backgroundTheme">
          <Form.Label className="h3 pt-2 mt-2">Background Theme</Form.Label>
          <Form.Control type="text" as="select"
          value={ settings.uiMode }
          defaultValue={UI_MODES.light}
          onChange={ this.onChange.bind(this, 'uiMode') }>
                <option label="Dark Mode" value={UI_MODES.dark}>Dark Mode</option>
                <option label="Light Mode" value={UI_MODES.light}>Light Mode</option>
          </Form.Control>
          <p className="text-muted pt-2">
            Select one of two background modes.
          </p>
        </Form.Group>
        </div>

        {/* TODO: */}
        {/* <Form.Group controlId="saveDeletedTweets">
          <Form.Check type="checkbox" label="TODO: Save deleted tweets to Brizzly" />
          ...and make a trigger for an alert box that notifies of saved changes and fades out slowly
        </Form.Group> */}
        <div className="pl-2 pr-2">
        <Form.Group controlId="customPrompt">
        <Form.Label className="h3 pt-2">Custom Prompt</Form.Label>
        <Form.Control type="Text" 
          value={ String(settings.customPrompt) }
          onChange={ this.onChange.bind(this, 'customPrompt') }/>
          <p className="text-muted pt-2">
            Twitter's default prompt is "What's happening?" but on Brizzly you can change it. Maybe something like "What's up, buttercup?", "What's gotten into you today?" or "What kind of sandwich are you eating?".
          </p>
        </Form.Group>
        </div>

        {this.renderAlert()}
        <Button disabled={this.state.saving} variant="success" type="submit" className="float-right pb-2 pt-2 ">
          {
            this.state.saving ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Saving...</span>
              </Spinner>
            ) : 'Save changes'
          }
        </Button>        
      </Form>
    )
  }

  renderAlert() {
    if (this.state.showError) {
      return (
        <Alert variant={this.state.errorType} onClose={() => this.setState({showError: false})} dismissible>
          <p>
            {this.state.errorText}
          </p>
        </Alert>
      )
    }
  }

  renderDeleteSettings() {
    let settings;
    if (!this.state.token) {
      return <div/>
    }
    else {
      settings = this.state.token.settings;
    }

    return (
      <div>
        <Collapse in={ this.showAutoDeleteSettings() }>
          <div id="example-collapse-text" className="pl-2 pr-2">
          <Form.Group controlId="skipDeleteLikes">
          <Form.Label className="h3 pt-2">Fave to Save</Form.Label>
          <Form.Control type="number" placeholder="Enter a number of likes" 
          value={ String(settings.skipDeleteLikes) }
          onChange={ this.onChange.bind(this, 'skipDeleteLikes') }/>
          <p className="text-muted pt-2">
            We'll monitor your Twitter account and will <strong>not</strong> auto-delete your tweets <strong>if</strong> they receive this amount of likes. For example, if I wanted to save all tweets that receive at least 20 likes, I would enter 20 above.
          </p>
        </Form.Group>
        </div>
        </Collapse>
      </div>
    )
  }

  /**
   * Render confirm modal
   */
  renderConfirmModal() {
    return (
      <Modal show={this.state.showConfirmModal} onHide={this.closeConfirmModal} className="ibmplexsans">
        <Modal.Header closeButton>
          <Modal.Title>Turn on Auto-delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>This feature will delete your tweets from now for all tweets made <strong>on</strong> or <strong>off</strong> Brizzly. Are you sure you want to turn on auto-delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeConfirmModal}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={this.activateAutoDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   * Activate auto-delete
   * - NOTE: does not save currently
   */
  activateAutoDelete = ()=> {
    this.setState({
      showConfirmModal: false,
    })
  }

  // - revert back if user closes modal
  closeConfirmModal = ()=> {
    let token = Object.assign({}, this.state.token);
    token.settings = Object.assign({}, token.settings, {postAutoDeleteTime: 0 });

    this.setState({
      showConfirmModal: false,
      token: token
    })
  }
  
  showAutoDeleteSettings() {
    if (this.state.token.settings && this.state.token.settings.postAutoDeleteTime && this.state.token.settings.postAutoDeleteTime > 0) {
      return true;
    }
    return false;
  }

  onSubmit = (e)=> {
    e.preventDefault();

    this.setState({saving: true});

    // Create copy of token
    let token = new OauthToken(this.state.token);

    // Convert from UI specific stuff back to correct types
    token.settings.postDelayTime = Number(token.settings.postDelayTime) || null;
    token.settings.postAutoDeleteTime = Number(token.settings.postAutoDeleteTime) || null;
    token.settings.skipDeleteLikes = Number(token.settings.skipDeleteLikes) || null;


    token.settings.customPrompt = String(token.settings.customPrompt) || null;

    TokenActions.updateSettings(token.id, token)
    .then(res => {
      this.setState({
        saving: false,
        showError: false
      });
    })
    .catch(err => {
      this.setState({
        saving: false,
        showError: true,
        errorText: ALERTS.saveSettings
      });
    })
  }

  onChange(field: string, e) {
    let val = e.target.value;
    let token = Object.assign({}, this.state.token);
    token.settings = Object.assign({}, token.settings, { [ field ]: val });

    // If this is the auto delete settings
    // and user is turning on the auto delete
    // show the confirm modal
    if (field === 'postAutoDeleteTime') {
      let oldValue = this.state.token.settings.postAutoDeleteTime;

      if (Number(oldValue) === 0 && Number(val) !== 0) {
        this.setState({ 
          token: token,
          showConfirmModal: true
        });
      }
    }

    this.setState({token: token});
  }

  onTokenChange = ()=> {
    this.setState({token: TokenStore.getActive() });
  }
}