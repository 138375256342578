import React from 'react'
import VideoPlayer from 'react-video-js-player'
import styles from './styles'

class Video extends React.Component {
  render () {
    let {media, gif, autoPlay} = this.props, videoSrc = ''

    media[0].video_info.variants.forEach( v => {
      if (v.url.indexOf('.mp4') > -1) {
        videoSrc = v.url;
      }
    })

    return (
      <div className="AdaptiveMedia">
          <VideoPlayer
              src={videoSrc} 
              controls={!gif} 
              autoPlay={true} 
              loop={gif} style={styles.video}
              width={500}
              poster={media[0].media_url_https}
          />
      </div>
    )
  }
}

export default Video