import React from "react";
import { TimelineActions } from '../actions/timeline';
import PostStore from '../stores/post';
import TweetStore from '../stores/tweet';
import { Post } from "../models/post";
import { Tweet } from '../models/tweet';
import { Col,Row,Container } from 'react-bootstrap';
import TweetList from '../components/TweetList';
import { ALERTS } from '../constants';
import TokenStore from '../stores/token';

export default class Profile extends React.Component  {

  props: {
    showError?: (message: string, type?: string)=>void
  }

  state: {
    pendingPosts: Array<Post>;
    tweets: Array<Tweet>
  }

  constructor(props) {
    super(props);

    this.state = {
      pendingPosts: PostStore.getAllByCreated(),
      tweets: TweetStore.getRecentByUser(),
      hasMore: true
    }
  }

  componentDidMount() {
    PostStore.addChangeListener(this.onTweetsChange);
    TweetStore.addChangeListener(this.onTweetsChange);
    TokenStore.addChangeListener(this.onTweetsChange);

    this.loadProfile();
  }

  componentWillUnmount() {
    PostStore.removeChangeListener(this.onTweetsChange);
    TweetStore.removeChangeListener(this.onTweetsChange);
    TokenStore.removeChangeListener(this.onTweetsChange);
  }

  render() {

    return (
      <Container fluid className="main">
      <Row className="pt-2">
        <Col xs={0} md={3}></Col>
        <Col xs={12} md={6}>
  <div className="pt-4">
        <h5>Profile</h5>
        
        <div className="mt-4">
          {/* <h5 className="pt-4">Your Timeline</h5> */}

          <TweetList
            pendingPosts={this.state.pendingPosts}
            tweets={this.state.tweets}
            loadLatest={this.loadProfile}
            loadOlder={this.loadOlder}
            hasMore={this.state.hasMore}
            {...this.props} />
        </div>
      </div>
      </Col>
      <Col xs={0} md={3}></Col>
      </Row>
    </Container>
      
    )
  }

  loadProfile = ()=> {

    // By default it load the most recent
    TimelineActions.loadProfile()
    .then(this._handleLoad)
    .catch(err => {

      if (err.details && err.details.code === 'oauth_token') {
        this.props.showError(ALERTS.connectTwitter, 'info');
      }
      else {
        this.props.showError(ALERTS.profileLoadErr);
      }
    })
  }

  loadOlder = ()=> {
    let oldest = this.state.tweets[ this.state.tweets.length - 1];

    if (oldest && oldest.post && !this.state.loading) {
      this.setState({loading: true});

      TimelineActions.loadProfile({after_date: oldest.post.created })
      .then(this._handleLoad)
      .catch(err => {
        this.setState({loading: false});

        this.props.showError(ALERTS.timelineLoadErr);
      });
    }
  }

  _handleLoad = (res)=> {
    let nextState = {loading: false};

    // If there's nothing new
    if (res.pendingPosts.length === 0 && res.tweets.length === 0) {
      nextState.hasMore = false;
    }

    this.setState(nextState);
  }

  onTweetsChange = ()=> {
    this.setState({
      pendingPosts: PostStore.getAllByCreated(),
      tweets: TweetStore.getRecentByUser()
    })
  }
}