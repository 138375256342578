import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { TimelineActions } from "../actions/timeline";
import CreatePost from "../components/CreatePost";

import PostStore from "../stores/post";
import TweetStore from "../stores/tweet";
import TokenStore from '../stores/token';
import { Post } from "../models/post";
import { Tweet } from "../models/tweet";
import { ALERTS } from "../constants";
import TweetList from '../components/TweetList';

export default class Home extends React.Component {
  props: {
    showError?: (message: string, type?: string) => void
  };

  state: {
    pendingPosts: Array<Post>,
    tweets: Array<Tweet>,
    loading: boolean
  };

  constructor(props) {
    super(props);

    this.state = {
      pendingPosts: PostStore.getAllByCreated(),
      tweets: TweetStore.getAllByCreated(),
      loading: true
    };
  }

  onTweetsChange = () => {
    this.setState({
      pendingPosts: PostStore.getAllByCreated(),
      tweets: TweetStore.getAllByCreated()
    });
  };

  componentDidMount() {
    PostStore.addChangeListener(this.onTweetsChange);
    TweetStore.addChangeListener(this.onTweetsChange);
    TokenStore.addChangeListener(this.onTweetsChange);

    this.loadTimeline();
  }

  componentWillUnmount() {
    PostStore.removeChangeListener(this.onTweetsChange);
    TweetStore.removeChangeListener(this.onTweetsChange);
    TokenStore.removeChangeListener(this.onTweetsChange);
  }

  render() {
    return (
      <div>
        <Container fluid className="main">
          <Row className="pt-2">
            <Col xs={0} md={3}></Col>
            <Col xs={12} md={6}>
              <h5 className="light">Home</h5>
              {/* <CreatePost /> */}
              
              <div className="mt-4">
                <TweetList
                  pendingPosts={this.state.pendingPosts}
                  tweets={this.state.tweets}
                  loadLatest={this.loadLatest}
                  loadOlder={this.loadOlder}
                  hasMore={true}
                  {...this.props} />
              </div>
            </Col>
            <Col xs={0} md={3}></Col>
          </Row>
        </Container>
      </div>
    );
  }

  loadTimeline() {
    TimelineActions.loadTimeline()
      .then(res => {
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });

        if (err.details && err.details.code === "oauth_token") {
          this.props.showError(ALERTS.connectTwitter, "info");
        } else {
          this.props.showError(ALERTS.timelineLoadErr);
        }
      });
  }

  loadOlder = () => {
    let oldest = this.state.tweets[this.state.tweets.length - 1];
    if (oldest && !this.state.loading) {
      this.setState({ loading: true });

      TimelineActions.loadTimeline({ max_id: oldest.id_str })
        .then(res => {
          this.setState({ loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });

          this.props.showError(ALERTS.timelineLoadErr);
        });
    }
  };

  loadLatest = () => {
    let latestTweet = this.state.tweets[0];
    if (latestTweet && !this.state.loading) {
      this.setState({ loading: true });

      TimelineActions.loadTimeline({ since_id: latestTweet.id_str })
        .then(res => {
          this.setState({ loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });

          this.props.showError(ALERTS.timelineLoadErr);
        });
    }
  };
}
