import React from 'react';
import ReactDOM from 'react-dom';
import { redirectTo } from "@reach/router"

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { firebaseApp } from "./firebase";
import { UserActions } from './actions/user';
import TokenStore from './stores/token';
import { UI_MODES, getUIModeClasses, getClassName } from './models/oauth_token';
import { ROUTES } from './constants';

import App from './App';
import { TokenActions } from './actions/token';
// import * as serviceWorker from './serviceWorker';

let app;
function runApp() {
  if (!app) {
    app = ReactDOM.render(<App />, document.getElementById('root'));
  }
}

function loadProfileAndInitApp(uid: string) {

  // Listen to updates to user
  UserActions.load()
  .then(res => {

    // Load twitter tokens
    TokenActions.loadTokens()
    .catch(err => {});

    runApp();
  })
  .catch(err => {
    runApp();

    redirectTo(ROUTES.signup)
  })
}


/**
 * Watch Firebase for auth changes
 */
firebaseApp.auth().onAuthStateChanged((user) => {
  if (user) {
    loadProfileAndInitApp(user.uid);
  } 
  else if (app) {
    redirectTo(ROUTES.signup)
  }
  else {
    runApp();
  }
});

TokenStore.addChangeListener(()=> {

  let token = TokenStore.getActive();

  // If a ui mode is set
  if (token && token.settings && token.settings.uiMode) {

    // If already set
    if (document.body.classList.contains( getClassName(token.settings.uiMode) )) {
      return
    }
    else {
      let keys = getUIModeClasses();
      document.body.classList.remove(...keys);
      document.body.classList.add( getClassName(token.settings.uiMode) )
    }
  }
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
