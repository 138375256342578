import React, { Component } from "react";
import { Form, Button,Spinner } from "react-bootstrap";
import {
  CardElement,
  injectStripe,
  ReactStripeElements
} from "react-stripe-elements";
import Accordion from 'react-bootstrap/Accordion'
import { UserActions } from "../actions/user";
import { ALERTS } from '../constants';

/**
 * Props overridden to work with stripe
 */
class CheckoutForm extends Component<any> {
  props: { [key: string]: any };

  state = {
    name: "",
    coupon: '',
    cardComplete: false,

    errorMessage: "",
    saving: false,
    applyingCoupon: false
  };

  render() {
    return (
      <div className="">
        <main className="page payment-page">
          <section className="payment-form dark">
            <div className="card-details">
              <h2>Add a Payment Method</h2>
              <Form onSubmit={this.handleSubmit.bind(this)}>
                <div className="col-lg-8 float-left">
                  <Form.Group controlId="name">
                    <Form.Label className="title border-0">
                      CARD HOLDER
                    </Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="Enter card holder name"
                      onChange={this.handleInputs.bind(this, "name")}
                      value={this.state.name}
                    />
                  </Form.Group>

                  <Form.Group controlId="">
                    <Form.Label>CARD NUMBER</Form.Label>
                    <CardElement
                      className="form-control"
                      onChange={this.handleChange}
                    />
                    <Form.Text className="text-muted" role="alert">
                      {this.state.errorMessage}
                    </Form.Text>
                  </Form.Group>

                  <Accordion defaultActiveKey="0">
                  <Form.Group controlId="coupon">
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    <Form.Label className="promo-code">
                      ADD A PROMO CODE
                    </Form.Label>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                      <div className="form-inline mx-auto">
                      <Form.Control
                        type="string"
                        placeholder="Enter promo code"
                        onChange={this.handleInputs.bind(this, "coupon")}
                        value={this.state.coupon}
                        disabled={this.state.applyingCoupon}
                      />

                      <button className="btn btn-link mt-0 ml-3" 
                        disabled={this.state.applyingCoupon}
                        onClick={this.applyCoupon.bind(this)}>
                          Apply Code
                      </button>
                    </div>
                    </Accordion.Collapse>
                  </Form.Group>
                  </Accordion>
                </div>

                {this.renderPlan()}
                
                <Button
                  className="btn btn-primary btn-block"
                  variant="primary"
                  type="submit"
                  disabled={this.state.saving || this.state.applyingCoupon || this.isFormIncomplete()}
                >
                  {this.state.saving ? "Saving..." : "Checkout"}
                </Button>
              </Form>
            </div>
          </section>
        </main>
      </div>
    );
  }

  renderPlan() {
    let plan = this.props.selectedPlan || {};

    return (
      <Form.Group controlId="products">
        <div className="products col-lg-4 float-right mb-4">
          <h3 className="title">Checkout</h3>
          <div className="item">
            <span className="price">
              {this.renderAmount(plan.amount)}
            </span>
            <p className="item-name">{plan.name}</p>
            <p className="item-description">
              {plan.checkoutTerms}
            </p>
          </div>

          {this.renderCoupon()}

          <div className="total">
            Total
            <span className="price">
              {this.renderAmount(plan.amount)}
            </span>
          </div>
          <p className="item-description mt-4">Card will be charged at the end of 2-week free trial.</p>
        </div>
      </Form.Group>
    )
  }

  renderCoupon() {
    let coupon = this.getCoupon();

    if (coupon) {
      return (
        <div className="item">
          <p className="">
            Promo code: {coupon.name}
          </p>
        </div>
      )
    }
  }

  getCoupon() {
    let user = this.props.user;

    if (user && user.customer && user.customer.discount && user.customer.discount.coupon && user.customer.discount.coupon.valid) {
      return user.customer.discount.coupon;
    }
    return null;
  }

  renderAmount(amount: number) {
    let coupon = this.getCoupon();
    let total = amount / 100;

    if (coupon) {
      if (coupon.percent_off) {
        total = total - (total * (coupon.percent_off / 100));
      }
      else if (coupon.amount_off) {
        total = total - coupon.amount_off / 100;
      }
    }

    return `$${total}`;
  }

  handleInputs(field: string, e) {
    this.setState({ [field]: e.target.value });
  }

  handleChange = data => {
    let nextState: {[key: string]: any} = {};
    
    if (data.error) {
      nextState.errorMessage = data.error.message;
    }
    else {
      nextState.errorMessage = '';
    }

    nextState.cardComplete = data.complete;

    this.setState(nextState);
  };

  /**
   * Is form incomplete
   */
  isFormIncomplete() {
    let state = this.state;
    if (!state.cardComplete || state.name.trim() === '') {
      return true;
    }
    return false;
  }

  /**
   * Apply coupon
   */
  applyCoupon() {
    if (this.state.coupon.trim() === '') {
      return
    }

    this.setState({applyingCoupon: true})

    UserActions.applyCoupon(this.state.coupon)
    .catch(err => {

      // Handle custom errors
      let errMessage = ALERTS.defaultErrorBody;
      if (err.details && err.details.code === 'bad_coupon') {
        errMessage = ALERTS.couponErr;
      }

      this.props.showError(errMessage);
    })
    .finally(()=> {
      this.setState({applyingCoupon: false})
    }) 
  }

  /**
   * Handle submit
   */
  handleSubmit = e => {
    e.preventDefault();

    if (this.props.stripe) {

      // Validate
      if (this.isFormIncomplete()) {
        return
      }

      this.setState({ saving: true });

      // Create Token
      let data = { name: this.state.name.trim() };

      this.props.stripe
        .createToken(data)
        .then(async tokenRes => {
          let token = tokenRes.token;

          // Start checkout
          let res = await UserActions.checkout(
            this.props.selectedPlan.id,
            token.id,
            this.state.coupon
          );
        })
        .catch(err => {
          console.log("Failed: ", err);

          // Handle custom errors
          let errMessage = ALERTS.checkoutErr;
          if (err.details && err.details.code === 'bad_coupon') {
            errMessage = ALERTS.couponErr;
          }

          this.props.showError(errMessage);
        })
        .finally(()=> {
          this.setState({ saving: false });
        })
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
}

export default injectStripe(CheckoutForm);
