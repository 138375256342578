import React from "react";
import { Card, Button, Dropdown } from 'react-bootstrap';
import { navigate } from "@reach/router"
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRetweet, faComment, faHeart,faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { formatDistanceToNow } from 'date-fns'
import { TimelineActions } from '../../actions/timeline';
import { Tweet } from "../../models/tweet";
import { VerifiedIcon, TwitterLogo } from '../../icons/twitter';
import Text from './Text';
import Quote from './Quote'
import Media from './Media';
import Countdown from './Countdown';
import { abbreviateNumber } from '../../utils/misc';
import { ALERTS } from '../../constants';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const Retweet = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default class TweetComponent extends React.Component  {

  props: {
    data: Tweet,
    isPost?: boolean;
    retweetUser?: {};
    showError?: (message: string, type?: string)=>void,
    isRetweetPreview?: boolean;
  }

  render() {
    let data = this.props.data;
    return this.renderTweet(data);
  }

  renderTweet(tweet: Tweet) {
    let post = this.props.data.post;
    let retweet = tweet.retweeted_status;
    let retweetedUser;
    let isRetweetPreview = this.props.isRetweetPreview;
    if (this.props.retweetUser) {
      retweetedUser = (
        <div>
          <FontAwesomeIcon icon={faRetweet} size="1x" /> {this.props.retweetUser.name} Retweeted
        </div>
      )
    }

    if (retweet) {
      return (
        <TweetComponent 
          data={tweet.retweeted_status} 
          retweetUser={tweet.user} 
          originalTweet={tweet} 
          retweet={this.props.retweet}
          reply={this.props.reply}
          isRetweetPreview={this.props.isRetweetPreview}
          isReplyPreview={this.props.isReplyPreview} />
      )
    }

    let QuoteComponent;
    if (tweet.quoted_status) {
      QuoteComponent = <Quote data={tweet.quoted_status} isRetweetPreview={this.props.isRetweetPreview}
      isReplyPreview={this.props.isReplyPreview} />
    }

    return (
      <Card style={{ width: '100%'}} className="tweet-card border-0">
        <Card.Body className="border-0">
          {!isRetweetPreview && <div>
            {retweetedUser}
            {this.renderTweetTop(retweet ? retweet : tweet)}
          </div> } 

          <div className="tweet-text">
            <Text data={retweet ? retweet : tweet} />
            </div>

          {this.renderMedia(tweet)}
 
          {QuoteComponent}

          {this.renderTweetBottom(retweet ? retweet : tweet)}

          {post && <Countdown post={post}/> }
          </Card.Body>
      </Card>
    )
  }

  /**
   * Render top of tweet
   */
  renderTweetTop(tweet: Tweet) {
    if (!this.props.isRetweetPreview) {
      return (
        <div className="tweet-top">
          { this.renderUser(tweet.user) }
          <a href={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`} target="_blank"><TwitterLogo style={{width: 20}}/></a>
        </div>
      )
    }
  }

  /**
   * Render media
   */
  renderMedia(tweet: Tweet) {
    let retweet = tweet.retweeted_status;

    if (!this.props.isReplyPreview) {
      return (
        <div className="tweet-media">
          <Media data={retweet ? retweet : tweet}/>
        </div>
      )
    }
  }

  /**
   * Render bottom
   */
  renderTweetBottom(tweet: Tweet) {
    if (this.props.isRetweetPreview || this.props.isReplyPreview) {
      return
    }

    let timestamp = formatDistanceToNow( new Date(tweet.created_at), 'h:m a · MMM d');
    
    let favorite_count = abbreviateNumber(tweet.favorite_count);

    return (
      <Card.Subtitle className="mr-auto mb-2 text-muted tweet-bottom">
        <div className="timestamp">
        <a href={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`} target="_blank" className="text-muted">
          {timestamp} ago
        </a>
        </div>
        <div className="tweet-actions">
          <div className="action" >
            <span 
              onClick={this.reply}>
              <FontAwesomeIcon icon={faComment} size="1x" />
            </span>
          </div>
          {this._renderRetweet()}
          <div className="action">
            <span 
              onClick={this.like} 
              className={ classnames({'liked': this.getTweet().favorited }) }>
              <FontAwesomeIcon icon={faHeart} size="1x" /> {favorite_count}
            </span>
          </div>
        </div>

      <div>
        {this.renderPostButtons()}
      </div>
      </Card.Subtitle>
    )
  }

  renderPostButtons() {
    let post = this.props.data.post;

    if (post) {
      return (
        <div className="post-actions">
          <Button variant="outline-secondary ml-n3" onClick={ this.edit }>
          {post.status === 'pending' ? 'Undo' : 'Redo'}
            <Countdown post={post} counter={true} />
          </Button>
          
          <Button className="ml-1 outline-secondary" onClick={ this.del }>
           <FontAwesomeIcon icon={faTrashAlt} size="1x"/>
          </Button>
        </div>
      )
    }
  }

  renderUser(user: Tweet['user']) {
    return (
      <div className="tweet-user">
        <img src={user.profile_image_url_https} />

        <div className="name-box">
          <div className="name">
            {user.name} { user.verified && <VerifiedIcon style={{width: 18}}/>} 
            <div className="screenName ml-2">{'@' + user.screen_name}</div>
          </div>
        </div>
      </div>
    )
  }

  _renderRetweet() {
    let tweet = this.getTweet();
    let retweet_count = abbreviateNumber(tweet.retweet_count);
    let retweetAction;

    if (tweet.retweeted) {
      retweetAction = (
        <Dropdown.Item 
          eventKey="unretweet">
          <FontAwesomeIcon icon={faRetweet} size="1x" /> Unretweet
        </Dropdown.Item>
      )
    }
    else {
      retweetAction = (
        <Dropdown.Item 
          eventKey="retweet">
          <FontAwesomeIcon icon={faRetweet} size="1x" /> Retweet</Dropdown.Item>
      )
    }

    return (
      <div className={ classnames('action', {'retweeted': tweet.retweeted })}>
        <Dropdown drop="left" onSelect={this.retweet}>
          <Dropdown.Toggle as={Retweet} id="dropdown-basic">
            <FontAwesomeIcon icon={faRetweet} size="1x" /> {retweet_count}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {retweetAction}
            <Dropdown.Item
              eventKey="retweet_comment">
              <FontAwesomeIcon icon={faPencilAlt} size="1x" />  Retweet with comment</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  getTweet() {
    if (this.props.originalTweet) {
      return this.props.originalTweet;
    }
    else {
      return this.props.data;
    }
  }

  /**
   * Like tweet
   */
  like = ()=> {
    let tweet = this.getTweet();

    // If already liked
    if (tweet.favorited) {
      TimelineActions.tweetUnlike(tweet.id_str)
      .catch(err => {
        this.props.showError(ALERTS.tweetUnlikeErr)
      })
    }
    else {
      TimelineActions.tweetLike(tweet.id_str)
      .catch(err => {
        this.props.showError(ALERTS.tweetLikeErr)
      })
    }
  }

  /**
   * retweet
   */
  retweet = (eventKey: string)=> {
    let tweet = this.getTweet();
    this.props.retweet(eventKey, tweet.id_str);
  }

  /**
   * reply
   */
  reply = ()=> {
    let tweet = this.getTweet();
    this.props.reply(tweet.id_str);
  }

  edit = ()=> {
    let post = this.props.data.post;
    this.props.editTweet(post.id)
  }

  del = ()=> {
    let post = this.props.data.post;
    this.props.deleteTweet(post.id)
  }
}
