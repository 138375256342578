import BaseError from "../utils/error";
import { FullUser } from "twitter-d";

export const OAUTH_TYPE = {
  twitter: 'twitter'
}

/**
 * UI light vs dark mode utils
 */
export const UI_MODES = {
  light: 'light',
  dark: 'dark'
}

export const getClassName = (key: string)=> {
  return `ui_${key}`;
}

export const getUIModeClasses = () => {
  return Object.keys(UI_MODES).map(key => getClassName(key));
}

/**
 * Provider specific settings
 */
type SettingsTwitter = {
  
  postDelayTime: number; // time before posting new tweet (undo time)
  customPrompt: string; // sets custom Twitter prompt in UI

  // Default time to keep posts before delete
  postAutoDeleteTime: number; // seconds

  // Skip delete if tweet has this many likes
  // TODO: Expand this into it's own model?
  skipDeleteLikes: number;

  // UI theme - light vs dark
  uiMode: string;
}

export class OauthToken {

  id: string;
  created: string;
  updated: string;

  userId: string;
  provider: string; // twitter
  providerId: string; // screen_name for now

  settings: SettingsTwitter; // NOTE: Only twitter for now

  // Twitter stuff
  account?: FullUser;

  constructor(params?: OauthToken|any) {
    params = params || {};

    this.id = params.id;
    this.userId = params.userId || null;
    this.provider = params.provider || null;
    this.providerId = params.providerId || null;

    let now = new Date().toISOString();
    this.created = params.created || now;
    this.updated = params.updated || now;

    // Settings
    this.settings = params.settings;
    this.account = params.account;
  }

  setUpdated() {
    this.updated = new Date().toISOString();
    return this.updated;
  }

  toFirebase() {
    return {
      created: this.created,
      updated: this.updated,

      userId: this.userId,
      provider: this.provider,
      providerId: this.providerId
    }
  }

  // Don't expose token/secret to ui
  toUI() {
    return {
      id: this.id,
      created: this.created,
      updated: this.updated,

      userId: this.userId,
      provider: this.provider,
      providerId: this.providerId
    }
  }
}