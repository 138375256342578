export class Post {

  public id?: string;
  public userId: string;
  public created?: string;
  public updated?: string;

  public message: string;
  public delaySeconds?: number; // how long to delay before posting
  public status?: string; // post status
  public scheduled?: string; // time to post to twitter

  public autoDeleteTime?: number; // how long to keep post before deleting
  public autoDeleteTaskId?: string // task id in queue;
  public autoDeleteScheduled?: string; // timestamp to trigger delete 

  public taskId?: string; // id in task queue
  
  // Twitter specific stuff
  public tweetId?: string;
  public tweetReplyId?: string; // tweet id post is replying to
  
  // What ouath token is this tied to? For users to connect multiple accounts
  public tokenId: string; 

  constructor(data: Post) {
    this.id = data.id;
    this.userId = data.userId;
    this.message = data.message;
    this.delaySeconds = data.delaySeconds;

    // Default to user config if set
    this.autoDeleteTime = data.autoDeleteTime || null;
    this.autoDeleteTaskId = data.autoDeleteTaskId || null;
    this.autoDeleteScheduled = data.autoDeleteScheduled || null;
    
    // Set status to pending
    this.status = data.status || null;
    this.taskId = data.taskId || null;

    // Twitter specific stuff
    this.tweetId = data.tweetId || null;
    this.tweetReplyId = data.tweetReplyId || null;
    this.tokenId = data.tokenId || null;

    this.created = data.created;
    this.updated = data.updated;

    this.scheduled = data.scheduled;
  }

  static fromFirebase(doc) {
    let data: any = doc.data() || {};
    let post = new Post(data || {});
    post.id = doc.id;

    return post;
  }
}