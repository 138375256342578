const ENV = process.env.REACT_APP_FIREBASE_ENV || 'development';

const HOST = `${window.location.protocol
}//${window.location.hostname}`;

const config: {[key: string]: any} = {
  development: {
    API_BASE: `${HOST}:5000`,
    API_URL: `${HOST}:5000/brizzly-dev/us-central1`,
    stripe: {
      key: 'pk_test_zko5E1VpMcIBZi1nPaSkSx7s00bHui8MZF'
    },
    firebase: {
      apiKey: "AIzaSyD4Zriw4aE5C51kVZ82gE3dYGzLfTrvAE4",
      authDomain: "brizzly-dev.firebaseapp.com",
      databaseURL: "localhost:8080",
      projectId: "brizzly-dev",
      storageBucket: "brizzly-dev.appspot.com",
      messagingSenderId: "285702839070",
      appId: "1:285702839070:web:fb33dda5f1c06ef2b2870f"
    }
  },
  staging: {
    API_BASE: `https://us-central1-brizzly-dev.cloudfunctions.net`,
    API_URL: 'https://us-central1-brizzly-dev.cloudfunctions.net',
    stripe: {
      key: 'pk_test_zko5E1VpMcIBZi1nPaSkSx7s00bHui8MZF'
    },
    firebase: {
      apiKey: "AIzaSyD4Zriw4aE5C51kVZ82gE3dYGzLfTrvAE4",
      authDomain: "brizzly-dev.firebaseapp.com",
      databaseURL: "https://brizzly-dev.firebaseio.com",
      projectId: "brizzly-dev",
      storageBucket: "brizzly-dev.appspot.com",
      messagingSenderId: "285702839070",
      appId: "1:285702839070:web:fb33dda5f1c06ef2b2870f"
    }
  },
  production: {
    API_BASE: `https://us-central1-brizzly.cloudfunctions.net`,
    API_URL: 'https://us-central1-brizzly.cloudfunctions.net',
    stripe: {
      key: 'pk_live_2q1Dw05C6nOwMcEqepxUSJOR00nkc3tQXi'
    },
    firebase: {
      apiKey: "AIzaSyDn0jVpqs9-EmM6BEhbKoEChntlXfGQcRw",
      authDomain: "brizzly.firebaseapp.com",
      databaseURL: "https://brizzly.firebaseio.com",
      projectId: "brizzly",
      storageBucket: "brizzly.appspot.com",
      messagingSenderId: "874437265524",
      appId: "1:874437265524:web:555de6a1c025557ab32921",
      measurementId: "G-LPDTLB1WC5"
    }
  },
}

export default config[ENV];